









































































































































































































































































































































































@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-group-append .input-group-text {
  min-width: 60px;
}
